<template>
  <div class="container">
    <PublicHeader :totalName="$t('property').Transfer" />
    <div class="form">
      <div class="line">
        <div class="input" @click="handleClick(0)">{{ Source }}</div>
        <img src="@/assets/new/change.png" alt="" />
        <div class="input" @click="handleClick(1)">{{ Target }}</div>
      </div>
      <div class="title">
        {{ $t('property').dhsl }}
      </div>
      <van-field
        v-model="Amount"
        type="number"
        center
        clearable
        :label="Source"
        :placeholder="$t('property').tips"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="Amount = LegalAmountInfo[Source]"
            >{{ $t('property').qbdh }}</van-button
          >
        </template>
      </van-field>
      <div class="line">
        <div class="item">
          <div>{{ $t('property').dhhl }}</div>
          <div>{{ targerRate }}</div>
        </div>
        <div class="item">
          <div>{{ $t('property').ky }}{{ Source }}</div>
          <div>{{ LegalAmountInfo[Source] }}</div>
        </div>
        <div class="item">
          <div>{{ $t('property').kd }}{{ Target }}</div>
          <div>{{ (getTargeAmount - ExchangeFeeAmount).toFixed(6) }}</div>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <div>{{ $t('property').sxfbl }}</div>
          <div>{{ basicConfig.ExchangeFee / 100 }}</div>
        </div>
        <div class="item">
          <div>{{ $t('property').sxf }}</div>
          <div>
            {{ ExchangeFeeAmount }}
          </div>
        </div>
      </div>
    </div>
    <van-button class="btn" type="info" round block @click="handleSub">{{
      $t('public').submit
    }}</van-button>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        :confirm-button-text="$t('public').sure"
        :cancel-button-text="$t('public').cancel"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import transactionApi from '@/api/transaction'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
const columns = ['USDT', 'BTC', 'ETH']
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      Amount: null,
      Source: '',
      Target: '',
      columns: ['USDT', 'BTC', 'ETH'],
      show: false,
      info: {},
      LegalAmountInfo: {}
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
    getTargeAmount() {
      if (this.Amount) {
        return this.targerRate * this.Amount
      } else {
        return '0.000000'
      }
    },
    ExchangeFeeAmount() {
      return (
        (Number(this.getTargeAmount) * this.basicConfig.ExchangeFee) /
        100
      ).toFixed(6)
    },
    targerRate() {
      let rate = 0
      const info = this.info
      Object.keys(this.info).forEach(keys => {
        const arr = keys.split('To')
        if (
          arr[0].toUpperCase() === this.Source &&
          arr[1].toUpperCase() === this.Target
        ) {
          rate = info[keys]
          return true
        }
      })
      return rate
    }
  },
  mounted() {
    this.type = this.$route.query.type
    if (this.type !== 'USDT') {
      this.Target = 'USDT'
    } else {
      this.Target = 'BTC'
    }
    this.Source = this.type
    this.exchangeRate()
    this.getUserInfo()
  },
  methods: {
    async exchangeRate() {
      this.info = await transactionApi.exchangeRate()
    },
    handleClick(index) {
      if (index === 0) {
        this.columns = columns.filter(item => item !== this.Target)
      } else {
        this.columns = columns.filter(item => item !== this.Source)
      }
      this.selectIndex = index
      this.show = true
    },
    onConfirm(value) {
      if (this.selectIndex) {
        this.Target = value
      } else {
        this.Source = value
      }
      this.show = false
    },
    async getUserInfo() {
      const res = await userApi.userInfo()
      this.LegalAmountInfo = res.LegalAmountInfo
    },
    async handleSub() {
      if (!this.Amount) {
        this.$toast(this.$t('recharge').tip)
        return false
      }
      const form = {
        Source: this.Source,
        Target: this.Target,
        Amount: Number(this.Amount)
      }
      await transactionApi.legalChange(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;

  .form {
    margin: 20px 10px;
    border-radius: 4px;
    background: #17181e;
    padding: 15px;
    font-size: 14px;
    .title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    .van-field {
      margin: 10px 0 20px;
    }
    .line {
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin-bottom: 10px;
      img {
        width: 30px;
        height: 30px;
      }
      .input {
        width: 120px;
        height: 30px;
        text-align: left;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        border-radius: 3px;
      }
      .item {
        height: 40px;
        flex: 1;
        display: flex;
        line-height: 1.5;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
    }
  }
  .btn {
    margin: 30px auto 0;
    width: 95vw;
  }
}
</style>
